import Card from 'components/card/card'
import PostCarousel from 'components/post-carousel/post-carousel'
import { ICommonCopyDict } from 'interfaces/common-copy'
import { IPost } from 'interfaces/post'
import { IUiResources } from 'interfaces/ui-resource'
import * as React from 'react'

interface IProps {
    posts: IPost[]
    commonCopy: ICommonCopyDict
    uiResources: IUiResources
    rangeClassName?: string
    showArrowsInCarousel?: boolean
}

/**
 * Blog posts section that goes at the bottom
 * of pages
 * @param props Props
 * @returns React.ReactElement
 */
export const BlogPostsSection = ({
    posts,
    commonCopy,
    uiResources,
    rangeClassName = '',
    showArrowsInCarousel,
}: IProps): React.ReactElement => {
    if (!Array.isArray(posts) || !posts?.length) {
        return null
    }

    // render post as a featured article
    if (posts.length === 1) {
        const post = posts[0]

        return (
            <Card
                title={post?.title}
                cardTitle={commonCopy['featuredArticleLabel'].value}
                ctaLabel={uiResources['readArticleLabel'].value as string}
                ctaLink={'/blogs/journal/' + post?.slug}
                description={post?.excerpt}
                image={post?.coverImage}
                layout="horizontal"
                imageAlign={post?.imageSide}
                background={post?.featuredCardBackgroundColor}
            />
        )
    }

    return (
        <PostCarousel
            commonCopy={commonCopy}
            ctaLabel={uiResources['readArticleLabel'].value as string}
            rangeSliderLabel={uiResources?.['blogCarouselSliderLabel']?.value as string}
            posts={posts}
            rangeClassName={rangeClassName}
            showArrows={showArrowsInCarousel}
        />
    )
}
