import * as React from 'react'
import CarouselWithSlider from 'components/carousel-with-slider/carousel-with-slider'
import { IPost } from 'interfaces/post'
import { ICommonCopyDict } from 'interfaces/common-copy'
import Card from 'components/card/card'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

interface IProps {
    rangeSliderLabel: string
    posts: IPost[]
    commonCopy: ICommonCopyDict
    ctaLabel: string
    rangeClassName?: string
    showArrows?: boolean
}

const lgBreakPoint = Number(screens.lg.replace('px', ''))
const _2xlBreakPoint = Number(screens['2xl'].replace('px', ''))

const carouselResonsiveSetting = [
    {
        // screens greater than >= 1024px
        breakpoint: lgBreakPoint,
        settings: {
            // width of card + padding
            itemWidth: 263 + 20,
        },
    },
    {
        // screens greater than >= 1024px
        breakpoint: _2xlBreakPoint,
        settings: {
            // width of card + padding
            itemWidth: 300 + 20,
        },
    },
]

const PostCarousel = ({
    posts,
    rangeSliderLabel,
    commonCopy,
    ctaLabel,
    rangeClassName = '',
    showArrows,
}: IProps): React.ReactElement => {
    return (
        <CarouselWithSlider
            rangeSliderLabel={rangeSliderLabel}
            rangeClassName={rangeClassName}
            itemWidth={250 + 10} // width of card + padding
            snap={false}
            responsive={carouselResonsiveSetting}
            showArrows={showArrows}
        >
            {posts.map((post) => (
                <Card
                    key={post?.sys?.id}
                    title={post?.title}
                    cardTitle={commonCopy?.['onTheBlogLabel']?.value}
                    ctaLabel={ctaLabel}
                    ctaLink={'/blogs/journal/' + post?.slug}
                    description={post?.excerpt}
                    image={post?.coverImage}
                    className="mr-2.5 lg:mr-5 last:mr-0 w-full"
                />
            ))}
        </CarouselWithSlider>
    )
}

export default PostCarousel
