import * as React from 'react'
import TitleWithColonIcon from 'components/title-with-colon-icon/title-with-colon-icon'
import Link from 'next/link'
import { colors } from 'theme.cjs'
import { IImage } from 'interfaces/media'
import { IRichText } from 'interfaces/rich-text'
import styles from './style.module.css'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

enum LAYOUT {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical',
}

interface IProps {
    cardTitle: IRichText | React.ReactElement
    title: string
    description: string
    image: IImage
    ctaLink: string
    ctaLabel?: string
    layout?: LAYOUT | 'horizontal' | 'vertical'
    className?: string
    imageAlign?: 'left' | 'right'
    background?: string
    showTitleDivider?: boolean
    // replaces cta
    cardFooter?: React.ReactNode
    imageLoading?: 'lazy' | 'eager'
}

const _2xlBreakpoint = screens['2xl'].replace('px', '')
const lgBreakpoint = Number(screens['lg'].replace('px', ''))

const Card = ({
    title,
    cardTitle,
    description,
    image,
    ctaLink,
    ctaLabel,
    layout = LAYOUT.VERTICAL,
    className = '',
    imageAlign = 'left',
    background = 'white',
    showTitleDivider = true,
    cardFooter,
    imageLoading = 'lazy',
}: IProps): React.ReactElement => {
    const cardBackgroundColor = background ?? 'white'

    return (
        <div
            className={`
                flex rounded-2lg h-full overflow-hidden grow-0  w-full
                ${
                    layout === LAYOUT.HORIZONTAL
                        ? `flex-col ${imageAlign === 'right' ? 'lg:flex-row-reverse' : 'lg:flex-row'}`
                        : 'flex-col'
                }
                ${className}
            `}
        >
            {/* cover image */}
            {image && (
                <div
                    className={`${
                        layout === LAYOUT.HORIZONTAL ? 'grow lg:flex-basis-half' : styles['aspect-ratio-container']
                    }`}
                >
                    <picture>
                        <source
                            type="image/webp"
                            srcSet={`
                            ${image.url}?fm=webp&w=420 420w,
                            ${image.url}?fm=webp&w=768 768w,
                            ${image.url}?fm=webp&w=1024 1024w,
                            ${image.url}?fm=webp&w=1600 1600w
                        `}
                            // for vertical layout, 400px is a safe value
                            // assumption is that single vertical cards won't
                            // be very big
                            // *********************************************
                            // for horizontal layout, the maximum size the image
                            // will cover is 800px, in small screens, it will cover
                            // screen width, between that half of screen width
                            sizes={
                                layout === LAYOUT.HORIZONTAL
                                    ? `(min-width: ${_2xlBreakpoint}px) 800px, (min-width: ${lgBreakpoint}px) 50vw, 100vw`
                                    : '400px'
                            }
                        />
                        <source
                            type="image/jpeg"
                            srcSet={`
                            ${image.url}?fm=jpg&w=420 420w,
                            ${image.url}?fm=jpg&w=768 768w,
                            ${image.url}?fm=jpg&w=1024 1024w,
                            ${image.url}?fm=jpg&w=1600 1600w
                        `}
                            sizes={
                                layout === LAYOUT.HORIZONTAL
                                    ? `(min-width: ${_2xlBreakpoint}px) 800px, (min-width: ${lgBreakpoint}px) 50vw, 100vw`
                                    : '400px'
                            }
                        />
                        <img
                            src={`${image.url}?fm=jpg&w=768`}
                            alt={image.title}
                            height={image.height}
                            width={image.width}
                            className={`
                                h-full object-cover
                                ${styles['card-image']}
                                ${
                                    // lg:object-right needed because of chrome bug
                                    // where there's 1px white space between image and
                                    // card description, this only happens in certain
                                    // screen sizes and we only need to worry about
                                    // this in horizontal layout
                                    // note: this will focus the image from the right as
                                    // it's cropped to cover the available space
                                    layout === LAYOUT.VERTICAL ? styles['aspect-ratio-element'] : 'lg:object-right'
                                }
                            `}
                            loading={imageLoading}
                        />
                    </picture>
                </div>
            )}

            {/* card details */}
            <div
                className={`grow ${layout === LAYOUT.VERTICAL ? 'w-full' : 'flex-basis-half'}`}
                style={{ backgroundColor: cardBackgroundColor || 'white' }}
            >
                <div
                    className={`
                        p-5 flex flex-col justify-between h-full
                        ${layout === LAYOUT.HORIZONTAL ? 'lg:p-10' : ''}
                        ${cardBackgroundColor === 'white' ? 'text-black' : 'text-white'}
                    `}
                >
                    <div>
                        {React.isValidElement(cardTitle) ? (
                            cardTitle
                        ) : (
                            <TitleWithColonIcon
                                colonIconFill={cardBackgroundColor === 'white' ? colors.mineshaft : colors.white}
                                //@ts-ignore
                                content={cardTitle?.json}
                            />
                        )}
                        {showTitleDivider && (
                            <hr
                                className={`mt-2.5 ${
                                    cardBackgroundColor === 'white' ? 'border-black/30' : 'border-white/30'
                                }`}
                            />
                        )}
                        <div
                            className={`mt-2.5 tracking-tight leading-120% ${
                                layout === LAYOUT.HORIZONTAL ? 'text-1xl lg:text-3.25xl mt-3.5' : 'text-xl'
                            }`}
                        >
                            {ctaLink ? (
                                <Link href={ctaLink} className="hover:border-b">
                                    {title}
                                </Link>
                            ) : (
                                title
                            )}
                        </div>
                        <p
                            className={`mt-2.5 leading-140% ${
                                layout === LAYOUT.HORIZONTAL ? 'text-xbase lg:text-xl lg:mt-5' : 'text-sm'
                            }`}
                        >
                            {description}
                        </p>
                    </div>

                    {/* footer */}
                    {/* if footer is present, it replaces CTA */}
                    <div className="mt-5 lg:mt-6 flex">
                        {React.isValidElement(cardFooter)
                            ? cardFooter
                            : ctaLink && (
                                  <Link
                                      href={ctaLink}
                                      className={`button button-secondary py-2.5 ml-auto text-xbase font-bold inline-block ${
                                          cardBackgroundColor === 'white'
                                              ? 'text-black'
                                              : 'text-white border-white hover:border-black'
                                      }`}
                                  >
                                      {ctaLabel}
                                  </Link>
                              )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card
